/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import {
    Button,
    Container,
    Flex,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Text,
} from '@chakra-ui/react';
import {
    useTripById,
    useContact,
    useInvoice,
    useTrip,
} from 'api/hooks/ApiHooks';
import { useAuthContext } from 'common/context/authContext';
import { ReturnLink } from 'common/nav/returnLink';
import { StandardSpinner } from 'common/spinner/standarsSpinner';
import { formatSingleDate } from 'common/utilities/parser';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IInvoice } from 'common/interfaces/invoice';
import { generatePaymentUrl } from './service/netaxeptPayment';

export default function PaymentContainer() {
    const auth = useAuthContext();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const { data: trip } = useTrip(auth, searchParams.get('tripNumber'));

    const formatter = new Intl.NumberFormat('nb-NO', {
        style: 'currency',
        currency: 'NOK',
    });

    const {
        data: crmTrip,
        isFetching: isNapexDataFetching,
        isIdle: isNapexDataIdle,
    } = useTripById(auth, trip?.tripId);

    const { data: contactData, isFetching: isContactFetching } = useContact(
        auth,
        {
            onSuccess(data) {
                setFirstName((v) => v || data?.firstName || '');
                setLastName((v) => v || data?.lastName || '');
                setEmail((v) => v || data?.email || '');
                setPhone((v) => v || data?.phoneNumber?.number || '');
            },
        }
    );

    const {
        data: invoiceData,
        isFetching: isInvoiceFetching,
        isIdle: isInvoiceIdle,
    } = useInvoice(auth, trip?.tripId);

    const activePayment: IInvoice | undefined = invoiceData?.find(
        (invoice) => invoice.invoiceId === searchParams.get('invoiceId')
    );

    const [amount, setAmount] = React.useState('');
    const [paymentSessionLoading, setPaymentSessionLoading] =
        React.useState(false);

    React.useEffect(() => {
        if (!searchParams.get('invoiceId')) {
            navigate('/faktura', { replace: true });
        }
    });

    const [firstName, setFirstName] = React.useState(
        contactData?.firstName || ''
    );
    const [lastName, setLastName] = React.useState(contactData?.lastName || '');
    const [phone, setPhone] = React.useState(
        contactData?.phoneNumber?.number || ''
    );
    const [email, setEmail] = React.useState(contactData?.email || '');

    const isLoading =
        isInvoiceFetching ||
        isInvoiceIdle ||
        isContactFetching ||
        isNapexDataFetching ||
        isNapexDataIdle;

    const isNextDisabled = !firstName || !lastName || !phone || !email;

    return (
        <Container maxW='container.md'>
            {isLoading ? (
                <StandardSpinner title='Verifiserer betalingsinformasjon' />
            ) : (
                <div>
                    <ReturnLink to='/faktura' />
                    {!activePayment ? (
                        <Container maxW='container.md' height='30vh'>
                            <Text mt={2}>
                                Fant ingen utestående betalinger.
                            </Text>
                        </Container>
                    ) : (
                        <Container maxW='container.md'>
                            <Heading size='lg' mt={2} mb={8}>
                                Betaling
                            </Heading>
                            <Text>{crmTrip?.tripName}</Text>

                            <Text mb={2}>
                                {crmTrip &&
                                    formatSingleDate(
                                        crmTrip.departureDate,
                                        false
                                    )}
                            </Text>

                            <Text fontWeight='bold'>
                                Reisende:{' '}
                                {`${contactData?.firstName} ${contactData?.lastName}`}
                            </Text>
                            <Text fontWeight='bold' mb={4}>
                                Kundenr: {contactData?.accountingNumber}
                            </Text>

                            <Flex mt={12} justifyContent='space-between'>
                                <Heading size='md'>Beløp:</Heading>
                                <Input
                                    id='amount'
                                    type='number'
                                    value={amount}
                                    prefix='kr'
                                    borderColor='NorskTur.Navy'
                                    maxW={{ base: '50%', md: '30%' }}
                                    placeholder={formatter.format(
                                        activePayment.amount || NaN
                                    )}
                                    onChange={(e) => setAmount(e.target.value)}
                                />
                            </Flex>
                            <Text>
                                Du kan justere beløpet dersom du vil betale på
                                nedbetaling.
                            </Text>
                            <Flex
                                my={10}
                                justifyContent='space-between'
                                borderBottom='1px solid'
                                borderColor='NorskTur.GråMedium'
                                paddingBottom={2}
                            >
                                <Heading size='md'>Totalsum:</Heading>
                                <Heading size='md'>
                                    {formatter.format(activePayment.amount)}
                                </Heading>
                            </Flex>

                            <ContactInput
                                label='Fornavn'
                                value={firstName}
                                onValueChanged={setFirstName}
                            />
                            <ContactInput
                                label='Etternavn'
                                value={lastName}
                                onValueChanged={setLastName}
                            />
                            <ContactInput
                                label='Telefon'
                                value={phone}
                                onValueChanged={setPhone}
                            />
                            <ContactInput
                                label='E-post'
                                value={email}
                                onValueChanged={setEmail}
                            />
                            <Flex mt={12} justifyContent='space-between'>
                                <Button
                                    variant='inversed'
                                    onClick={() => navigate('/faktura')}
                                >
                                    Avbryt
                                </Button>
                                {isNextDisabled ? (
                                    <Button isDisabled>Neste</Button>
                                ) : (
                                    <a
                                        href={generatePaymentUrl({
                                            kid: activePayment?.kidNumber || '',
                                            amount:
                                                amount ||
                                                activePayment.amount.toString(),
                                            customerEmail: email,
                                            customerFirstName: firstName,
                                            customerLastName: lastName,
                                            customerNumber:
                                                contactData?.accountingNumber ||
                                                '',
                                            uuid: activePayment.invoiceId,
                                            tripName: crmTrip?.tripName || '',
                                        })}
                                        onClick={() =>
                                            setPaymentSessionLoading(true)
                                        }
                                    >
                                        <Button
                                            variant='primary'
                                            isLoading={paymentSessionLoading}
                                        >
                                            Neste
                                        </Button>
                                    </a>
                                )}
                            </Flex>
                        </Container>
                    )}
                </div>
            )}
        </Container>
    );
}

const ContactInput = (props: {
    label: string;
    value: string;
    onValueChanged: (v: string) => void;
}) => {
    const { label, value, onValueChanged } = props;
    return (
        <FormControl isInvalid={!value} mt={4}>
            <FormLabel>{label}</FormLabel>
            <Input
                id='first_name'
                borderColor='NorskTur.Navy'
                maxW={{ base: '100%', md: '50%' }}
                value={value}
                onChange={(e) => onValueChanged(e.target.value)}
            />
        </FormControl>
    );
};
